@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   404
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.error__txt {
  & + & {
    margin-top: 1.5em;
  }

  a {
    display: inline-block;
    color: v.$clr_dred;
    text-decoration: underline;
    padding: 0 .2em;

    @include m.hover {
      text-decoration: none;
    }
  }
}